<template>
  <el-dialog
      title="明细"
      :visible.sync="isOpenModal"
      :close-on-click-modal="false"
      width="1000px"
      @closed="closeModal" >
      <div style="width: 100%;">
        <ch-search
            :render-option="searchOption" :model="searchForm"
            :props="{ labelWidth: '66px'}">
        </ch-search>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '500px', size: 'mini'}">
        </ch-table>
      </div>
     <span slot="footer" class="flex_con">
         <ch-page
             type="old"
             ref="page"
             :getListFunction="getTableList"
         ></ch-page>
      </span>
  </el-dialog>
</template>
<script>
export default {
  data() {
    return {
      isOpenModal: false,
      isTableLoading: false,
      searchForm:{
        activityId: '',
        createTimeEnd: "",
        createTimeStart: "",
        orderNumber: "",
        phone: "",
        time: "",
      },
      tableList: [],
      status:['待支付','已取消','待使用','已过期','已完成','已退款中','已退款'],
    }
  },
  computed:{
    searchOption: function (){
      return [
        {type: 'input', label: '订单号', prop: 'orderNumber', placeholder: "请输入订单号", width:120,},
        {type: 'input', label: '手机号', prop: 'phone', placeholder: "请输入手机号", labelWidth: '56px',},
        {type: 'time', label: '使用时间', prop: 'time',props: {type: 'daterange'},format:"yyyy-MM-dd HH:mm:ss", placeholder: "请输入",},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text",  label: "商品名称",  prop: "goodsName",  showOverflowTooltip: true, },
        { column: "text",  label: "订单号",  prop: "orderNumber",  showOverflowTooltip: true,width:200, },
        { column: "text",  label: "手机号",  prop: "phone",  showOverflowTooltip: true, },
        { column: "text",  label: "优惠",  prop: "_discount",  showOverflowTooltip: true, },
        { column: "text",  label: "使用时间",  prop: "createTime",  showOverflowTooltip: true, },
        { column: "text",  label: "状态",  prop: "_status",  showOverflowTooltip: true, },
      ];
    },
  },
  methods: {
    openModal(id){
      this.isOpenModal = true;
      this.searchForm.activityId = id;
      this.getTableList();
    },
    searchHandle() {
      if(this.searchForm.time){
        const [start, end] = this.searchForm.time
        this.searchForm.createTimeStart = start
        this.searchForm.createTimeEnd = end
      }else{
        this.searchForm.createTimeStart = null
        this.searchForm.createTimeEnd = null
      }
      this.getTableList()
    },
    getTableList() {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmActivityCompany/detailList",this.searchForm).then((res) => {
        this.tableList = res.rows.map(item=>{
          item._status = this.status[item.status]
          if(!item.discountValue) {
            item._discount = '一'
          }else {
            if(item.discountThreshold == 0){
                item._discount = item.discountType == 1 ? `无门槛减${item.discountValue}元` : `无门槛打${item.discountValue}折`
            }else {
               item._discount = item.discountType == 1 ?  `满${item.discountThreshold}元减${item.discountValue}元`: `满${item.discountThreshold}元打${item.discountValue}折`
            }
          }
          return item
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    closeModal(){
      this.isOpenModal = false;
    }
  }
}
</script>
<style scoped lang="scss">

</style>