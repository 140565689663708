<template>
  <div>
    <ch-layout page search>
      <template #search>
        <div style="width: 100%;">
          <ch-search
              :render-option="searchOption" :model="searchForm"
              :props="{ labelWidth: '66px'}">
          </ch-search>
        </div>
      </template>
      <template v-slot:default>
        <ch-table :render-option="tableOption" :data="tableList"
                  :loading="isTableLoading"
                  :props="{width: '100%', height: '100%', size: 'mini'}">
          <template v-slot:handle="scope">
            <ch-button type="link" @click="openDetailList(scope.row.id)">使用明细</ch-button>
          </template>
        </ch-table>
      </template>

      <template v-slot:page>
        <ch-page
            type="old"
            ref="page"
            :getListFunction="getTableList"
        ></ch-page>
      </template>
    </ch-layout>
    <detail-list ref="detailList" />
  </div>
</template>

<script>
import detailList from './detailList.vue'
export default {
  components:{
    detailList,
  },
  data(){
    return{
      // 搜索相关
      searchForm: {
        startDate: null,
        endDate: null,
        userRange: null,
        discountType: null,
        name:null,
        time: null,
      },
      tableList: [], // table列表
      isTableLoading: false, // table加载
      disabled: true,
      communityList:[],
      communityGroup:[],
      typeOption:[{
        name:'家政',
        id:1,
      }],
      selectedOptions: [],
      classifyOption:[],
      userRangeOptions:[ // 1-未购用户, 2-已购用户, 3-不限制
        {label: '未购用户', value: 1},
        {label: '已购用户', value: 2},
        {label: '不限制', value: 3},
      ],
      discountTypeOptions: [ // 1-减免金额, 2-打折
        {label: '减免金额', value: 1},
        {label: '打折', value: 2},
      ],
    }
  },
  computed:{
    searchOption: function (){
      return [
        {type: 'input', label: '活动名称', prop: 'name', placeholder: "请输入活动名称",},
        {type: "select", label: "用户范围", prop: "userRange", placeholder: "请选择用户范围",
          name: "label", value: "value", option: this.userRangeOptions},
        {type: "select", label: "优惠类型", prop: "discountType", placeholder: "请选择优惠类型",
          name: "label", value: "value", option: this.discountTypeOptions },
        {type: 'time', label: '起始时间', prop: 'time',props: {type: 'daterange'},format:"yyyy-MM-dd HH:mm:ss", placeholder: "请输入",},
        {type: 'button', button: [
            {text: '查询', onClick: this.searchHandle},
            {text: '重置', onClick: this.searchHandle},
          ]},
      ]
    },
    tableOption:function (){
      return [
        { column: "type", label: "序号", prop: "", type: "index", width: "70",},
        { column: "text",  label: "平台活动名称",  prop: "name",  showOverflowTooltip: true, },
        { column: "text",  label: "用户范围",  prop: "_userRange",  showOverflowTooltip: true, },
        { column: "text",  label: "优惠类型",  prop: "_discountType",  showOverflowTooltip: true, },
        { column: "text",  label: "优惠",  prop: "_discount",  showOverflowTooltip: true, },
        { column: "text",  label: "开始时间",  prop: "startDate",  showOverflowTooltip: true, },
        { column: "text",  label: "结束时间",  prop: "endDate",  showOverflowTooltip: true, },
        { column: "slot", label: "操作", slotName: "handle", width: "160" },
      ];
    },
  },
  mounted() {
    this.getTableList()
  },
  methods:{
    // 查询
    searchHandle() {
      if(this.searchForm.time){
        const [start, end] = this.searchForm.time
        this.searchForm.startDate = start
        this.searchForm.endDate = end
      }else{
        this.searchForm.startDate = null
        this.searchForm.endDate = null
      }
      this.getTableList()
    },
    // 列表数据
    async getTableList(params=this.searchForm) {
      this.isTableLoading = true;
      this.$refs?.page?.getServerList("/hm/hmActivityCompany/list",params).then((res) => {
        this.tableList = res.rows.map(item=>{
          item._discountType = this.discountTypeOptions.find(v=> item.discountType.split(',').map(Number).includes(v.value)).label
          if(!item.discountValue) {
            item._discount = '一'
          }else {
            if(item.discountThreshold == 0){
              item._discount = item.discountType == 1 ? `无门槛减${item.discountValue}元` : `无门槛打${item.discountValue}折`
            }else {
              item._discount = item.discountType == 1 ?  `满${item.discountThreshold}元减${item.discountValue}元`: `满${item.discountThreshold}元打${item.discountValue}折`
            }
          }
          item._userRange = this.userRangeOptions.find(v=>v.value==item.userRange).label
          return item
        })
        this.isTableLoading = false
      }).catch(() => {this.isTableLoading = false});
    },
    openDetailList(id) {
      this.$refs.detailList.openModal(id)
    }
  }
}
</script>
